@import '../vars.scss';

.item-content{
    
    width: 100%;
    @include Body;
    @include col10; 
    margin-top: 30px;
    
    h2 {
        @include H2;
    }
    h4 {
        @include H3;
    }
    h4, h2 {
   
        letter-spacing: 0px;
        color: $heading-blue;
        margin-bottom: 20px;
        font-weight: 600;
            a {
                color: $heading-blue;
            } 
        }
    
    hr {
       
        margin: $spacer-12x 0 $spacer-4x;
    }
    .jobDesc{
        *
        {
            font-family: noto-sans, sans-serif !important;
            font-size: 100% !important;
        }
       
    }

}

   /* Adjust font size for smaller screens */
   @media screen and (max-width: 768px) {
        .item-content {
            font-size: 14px;
            h4{
                font-size: 16px;
            }
        }
    }

/* Adjust font size for even smaller screens */
@media screen and (max-width: 480px) {
    .item-content {
        font-size: 10px;
        h4{
            font-size: 14px;
        }
    }
    
}