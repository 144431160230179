@import '../vars.scss';

.content-tile {
    &.text-only { 
        width: 100%;
        padding-top: 30px;
        @media (max-width: $min-md - 1) {
            width: 75%;
        }
        .container {
            display: flex;
            margin-bottom: 20%;
            padding: 0;
            .text-container {
                text-align: left;
                width: 100%;
                .heading {
                    position: relative;
                    @include H5;
                    &:before {
                        content: '';
                        width: 100%;
                        height: 6px;
                        background: $blue-gray;
                        position: absolute;
                        left: 0;
                        top: -30px;
                    }
                }
                .paragraph {              
                    color: #3C3C3C;
                    @include Body;
                    p {
                        margin-bottom: 0;
                    }
                }
                a{
                    @include TextCTA;
                }
            } 
        }     
    }
    &.icon {  
        .container {
            padding-left: 0px;
            padding-right: 0px;
        }
        .text-container {
            width: 100%;
        }
        .paragraph  {
            color: $dark-text;
            @include Body;
        }
        @media (max-width: $min-lg) {
            .container {
                width: 70%;
                margin-bottom: 70px;
            }
        }
    }
    &.text-only-2 { 
        width: 100%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: $min-md - 1) {
            width: 75%;
        }
        .container {
            display: flex;
            margin-bottom: 10%;
            padding: 0;
            .text-container {
                text-align: left;
                width: 100%;
                .heading {
                    position: relative;
                    @include H5;
                    margin-bottom: 10px;
                }
                .paragraph {              
                    color: #3C3C3C;
                    @include Body;
                    font-style: italic;
                    p {
                        margin-bottom: 0;
                    }
                }
                a{
                    text-decoration: none;       
                    color: #3C3C3C;
                    float: right;
                    font-size: 16px;
                    @media (max-width: $min-xxl) {
                        font-size: 14px;
                    }
                }
            } 
        }     
    }
    &.small-image {
        box-shadow: 0px 10px 15px #0000001A;
        position: relative;
        height: 100%;
        >a {
            text-decoration: none;
        }
        .publication-img-fluid {
            object-fit: cover;
            height: 200px !important;
            width: 100%;
        }
        .container {
            padding-right:0;
            padding-left:0;
            .img-fluid {
                object-fit: cover;
            }
        }
        .video-container {
            display: flex;
        }
        .tile-shortdescription-container {
            text-align: left;
            padding: 12px 42px 12px 24px;
            display: flex;
            flex-direction:column;
        }
        .text-container, .tile-text-container {
            text-align: left;
            padding: 12px 42px 36px 24px;
            display: flex;
            flex-direction:column;
            .subheading, .tile-subheading {
                margin-top: 10px;
                color: $dark-text;
                @include Body;
                font-weight: 600;
            }
            .shortdescription, .tile-shortdescription {
                margin-top: 10px;
                color: $dark-text;
                @include Body;
                font-weight: 600;
            }
            .heading, .tile-heading  {
                position: relative;
                padding-top: 10px;
                margin-bottom: 10px;
                color: $heading-blue;
                @include H3;
            }
            .paragraph{
                padding-top: 10px;
                @include Body;
                color: $dark-text;
            }
            .footer{
                position: absolute;
                bottom: 12px;
                @include TextCTA;
                color: $dark-text;
            }
        }
    }
    &.large-image {
        box-shadow: 0px 10px 15px #0000001A;
        height: 100%;
        >a {
            text-decoration: none;
        }
        .img-fluid {
            display: inline-block;
            width: 100%;
            object-fit: cover;
            @media(min-width: $min-md){
                height: 200px;
            }
        }
        .image-container {
            position: relative;
        }
        .img-overlay {
            position: absolute;
            top: 38%;
            right: 16%;
            width: 70%;
        }
        .tile-text-container {
            padding: 12px 12px 26px 12px; 
        }
        .tile-heading, .tile-subheading{
            font-weight: 600;
        }
        .tile-subheading {
            color: $dark-text;
            @include Body;
            font-weight: 600;
            min-height: 24px;
            display: inline-block;
        }
        .tile-heading, .tile-shortdescription{
            font-weight: 600;
        }
        .tile-shortdescription {
            color: $dark-text;
            @include Body;
            font-weight: 600;
            min-height: 24px;
            display: inline-block;
        }
        .tile-heading{
            color: $blue-80;
            margin: 4px 0 24px;
            @include Body;
            font-weight: 600;
        }
        .paragraph {
            color: $dark-text;
            @include Body;
        }
        @media (max-width: 767px) {
            width: 60%;
        }
    }
    &.big-heading {
        position: relative;
        padding-bottom: 55px;
        .container{
            .text-container {
                .heading {
                    margin-bottom: 0;
                    @include H2;
                }
                .paragraph{
                    @include H4;
                    color: $dark-text;
                    &:empty {
                        display: none;
                    }
                }
                @media(max-width: $min-xl) {
                    width: 100%;
                    .heading {
                        text-align: left;
                    }
                }
                @media(max-width: $min-lg) { 
                   .heading {
                        text-align: center;
                    }
                }
            }
        }
    }
    &.image-no-shadow {
        padding-bottom: 20px;
        position: relative;
        width: 100%;
        .container {
            padding: 0;
            .img-fluid {
                object-fit: cover;
                min-height: 200px;
                width: 100%;
            }
            .text-container {
                text-align: left;
                .tile-cta {
                    position: absolute;
                    bottom: 0;
                    @include TextCTA;
                }
                .heading {
                    padding-top: 10px;
                    @include H3;
                    padding-top: 20px;
                    margin-bottom: 5%;
                }
                .paragraph{
                    @include Body;
                    color: $dark-text;
                    padding-bottom: 32px;
                    margin-bottom: 0;
                }
            }      
            @media(max-width: $min-lg) { 
                .img-fluid {
                    width: 624px;
                    height: 356px;
                    object-fit: cover;                    
                }
                .paragraph{
                    padding-bottom: 50px;                   
                }
            }
        }
    }
    &.left-image {
        > .container {
            display: flex;
        }
        border-radius: 10px;
        padding-top: 24px;
        padding-bottom: 24px;
        height: 100%;
        .heading {
            @include H3;
            margin-bottom: 10px;
            color: white;
        }
        .paragraph {
            @include Body;
            margin: 0px 0px 0px;
            width: 100%;
            color: white;
        }
        .img-container {
            flex-shrink: 0;
            max-width: 40px;
            margin-right: 24px;
            align-self: center;
        }
        @media (min-width: $min-xxl) {
            .img-container {
                max-width: 70px;
            }
        }
        @media (max-width: $min-xxl) and (min-width: $min-xl) {
            .img-container {
                max-width: 48px;
            }
        }
        @media(max-width: $min-sm) {
            .paragraph {
                padding-bottom: 10px;
            }
        }
    }    
    &.wide-image {
        height: 100%;
        >a {
            text-decoration: none;
        }
        .image-container {
            display: flex;
            height: 100%;
        }
        .img-fluid {      
            flex-shrink: 0;
            width: 100%;
            object-fit: cover;
        }
        .tile-text-container {
            text-align: left;
            padding-left: 30px;
            padding-bottom: calc(35px + 1.5rem);
            padding-top: 60px;
            display: flex;
            flex-direction:column;
            width: 100%;
            
            color: white; 

            position: fixed;
            bottom: 0;
            left: 0;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.15), rgba(0,0,0,0.3), rgba(0,0,0,0.5), rgba(0, 0, 0,0.7),rgba(0, 0, 0,0.85), rgba(0, 0, 0,0.95), rgb(0, 0, 0)); 
                opacity: 0.9;
                z-index: -1;
            }
            @media (max-width: $min-lg) {
                padding-top: 40px;
                padding-left: 24px;
                padding-bottom: calc(10px + 1.5rem);
            }
            @media (max-width: $min-md) {
                padding-bottom: calc(1.5rem);
            }
            @media (max-width: $min-sm) {
                padding-top: 20px;
                padding-left: 10px;
                padding-bottom: calc(5px + 1.5rem);
            }
            .tile-heading  {
                position: relative;
                font-weight: 400;
                width: 60%;
                @media (max-width: $min-xl) {
                    font-size: 18px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 14px;
                    margin-bottom: 4px;
                }
                @media (max-width: $min-md) {
                    font-size: 10px;
                }                
                @media (max-width: 500px) {
                    width: 80%;
                    margin-bottom: 0px;
                }
            }
            .paragraph{              
                font-weight: 500;
                font-size: 16px;
                color: white; 
                width: 60%;
                p {
                    margin-bottom: 0px;
                }
                @media (max-width: $min-xl) {
                    font-size: 12px;  
                }
                @media (max-width: $min-lg) {
                    font-size: 8px;
                }
                @media (max-width: $min-md) {
                    font-size: 6px;
                }
                @media (max-width: 500px) {
                    font-size: 5px;
                    width: 80%;
                }
            }
        }
    }
    a > .text-only {
        &:hover{
            text-decoration: underline;
        }
    }
    //background-color
    &.pink {
        background-color: #993D78;
    }
    &.blue {
        background-color: #2658AB;
    }
    &.green {
        background-color: #428F8E;
    }
    &.gray {
        background-color: $background-gray;
    }
    
}
.col .shortdescription {
    margin-left: -5px;
    margin-right: -5px;
}

.col {
    padding-bottom: 25px;
}