@import '../vars.scss';

.image-banner{
    // @include verticalSpacing;
    padding-top: $small-vertical-spacing * 1.5;
    padding-bottom: $small-vertical-spacing;
    @media(min-width: $min-xl){
        padding-top: $vertical-spacing * 1.5;
        padding-bottom: $vertical-spacing;
    }
    .heading{
        text-align: center;
    }
    .img-container{
        text-align: center;
    }
    &.gray {
        background-color: $background-gray;
    }
    &.white {
        background-color: white;
    }
}