@import '../_vars.scss';

.sort {
    margin-right: 40px;
    font-size: 14px;
    .dropdown-toggle {
        border-radius: 0;
        background: transparent;
        border-color: $border-gray;
        color: $darker-text;
        width: 180px;
        text-align: left;
        font-size: 14px;
        &:after {
            position: absolute;
            right: 12px;
            content: "\f282";
            font-family: bootstrap-icons !important;
            border: 0;
        }
    }
    .btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
        color: $darker-text;
        background: transparent;
    }
    .dropdown-menu {
        border-radius: 0;
        width: 100%;
        box-shadow: 0px 0px 6px #00000029;
    }
    .dropdown-item-text{
        color: $darker-text;
        font-weight: 600;
    }
    .form-check {
        padding-left: 42px;
    }
    .form-check-input,
    .form-check-label {
        cursor: pointer;
    }
    .form-check-input {
        border: none;
        border-radius: 0;
        &:checked {
            background: transparent;
            display: flex;
            align-items: center;
            &:before {
                font-size: 20px;
                content: "\f272";
                font-family: bootstrap-icons !important;
                color: $caption;
            }
        }
    }
    .dropdown-item-text,
    .form-check{
        margin: 6px 0;
    }
}
