@import '../_vars.scss';

.grant-page {
    @include col10;
    padding: $spacer-12x 0;
    .banner {
        .paragraph {
            display: none;
        }
    }
    .row {
        margin: 0;
    }
    .main-column{
        h2 {
            @include H3;
            color: $sp-blue;
            margin-bottom: 24px;       
            display: inline-block;
        }
        h4 {
            color: $sp-blue;
            @include Body;
            font-weight: 600;
        }
        blockquote {
            padding-left: $spacer-5x;
        }
        .img-fluid {
            float: left;
            margin-right: $spacer-1x;
        }
    }
    .side-bar {
        background: $background-gray;
        padding: 30px;
        padding-left: 40px;
        text-align: center;
        & > div {
            text-align: left;
            > p:first-child,
            > h3:first-child {
                @include H3;
                color: $sp-blue;
            }
        }
        h3 {
            color: $darker-text;
            @include H4;
            margin-bottom: 24px;
        }
        h4, p {
            color: $dark-text;
            @include Body;
        }
        h4 {
            margin-bottom: 2px;
            font-weight: 600;
        }
        p {
            margin-bottom: 28px;
        }
        p:empty {
            display: none;
        }
        ul {
            padding: 0;
            margin-left: $spacer-3x;
        }
        li p {
            margin-bottom: 0;
        }
    }
    ol > li > ol {
        list-style-type: lower-alpha;
    }
}