@import url("https://use.typekit.net/tlr3yqs.css"); // noto sans fonts
@import './customized_bootstrap.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import '../vars.scss';

.mainContent {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $font-sans;
    padding-top: 98px; //accounts for header
}

header {
    box-shadow: 0px 2px 6px $ui-gray;
    position: fixed;
    width: 100%;
    background: white;
    z-index: 1;
}

footer {
    border-top: 2px solid $light-ui-gray;
}