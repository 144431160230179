@import '../_vars.scss';

.search {
    border: 1px solid $border-gray;
    width: 316px;
    padding-left: 36px;
    height: 100%;
    font-size: 14px;
}

.search-container {
    position: relative;
    &:before {
        content: "\f52a";
        font-family: bootstrap-icons !important;
        color: $blue-40;
        position: absolute;
        transform: scaleX(-1);
        font-size: 18px;
        left: 12px;
        top: 4px;
    }

}
