@import '../vars.scss';

.mainContent  {
    div:last-child {
        .below{
            margin-bottom: ($vertical-spacing * 3);
            @media(max-width: $min-xl - 1){
                margin-bottom: $small-vertical-spacing * 4;
            }
        }
    }
}

.banner-container.floating-image + div > div {
    .cavd &, .ghdc & {
        padding-top: $vertical-spacing * 3.5;
        @media(max-width: $min-xl - 1){
            padding-top: $small-vertical-spacing * 3;
        }
        @media(max-width: $min-lg){
            padding-top: $small-vertical-spacing * 5;
        }
    }
    padding-top: $vertical-spacing * 2;
    @media(max-width: $min-xl - 1){
        padding-top: $small-vertical-spacing *2;
    }
}
.banner-container.below + div > div {
    .cavd &, .ghdc & {
        padding-top: $vertical-spacing * 4;
        @media(max-width: $min-xl - 1){
            padding-top: $small-vertical-spacing * 4;
        }
    }
    padding-top: $vertical-spacing * 3;
    @media(max-width: $min-xl - 1){
        padding-top: $small-vertical-spacing * 3;
    }
}

.banner {
    //banner variants
    @include verticalSpacing;
    &.floating-image {
        .text-container {
            padding-top: 0;
        }
        .heading {
            @include B1;
            .cavd & {
                @include H2;
            }
        }
        .paragraph {
            @include B2;
            .cavd & {
                @include H4;
            }
        }
        
        .img-container {
            .cavd &,.ghdc & {
                margin-bottom: -($vertical-spacing * 4)
            }
            max-height: 715px;
            margin-bottom: -($vertical-spacing * 2);
            text-align: center;

            .img-fluid {
                position: relative;
            }
        }
        @media(max-width: $min-lg) {
            .text-container {
                margin-bottom: 20px;
            }
            .img-fluid {
                width: 100%;
            }
        
        }
        @media(min-width: $min-lg + 1) {
            .text-container {
                padding-top: $spacer-8x;
            }
            .row > .col-lg-5,
            .row > .col-lg-7{
                width: 50%;
            }
        }
        @media(max-width: $min-xl - 1){
            .img-container {
                margin-bottom: -($small-vertical-spacing * 2);
            }
        }
    }
    //no image, one column of content centered
    &.none-one-column {
        display: flex;
        text-align: center;
        .ctvd &{
            padding-bottom: $small-vertical-spacing / 2;
        }
        .text-container {
            @include col8;
            padding: 0;
            margin: 0 auto;
        }
        .heading {
            position: relative;
            margin-bottom: 24px;
        }
        .paragraph {
            text-align: center;
            margin-bottom: 0;
            &:empty {
                display: none;
            }           
        }
        .cta {
            margin-top: 64px;
        }
        @media(max-width: $min-xl){
            padding-left: 70px;
            padding-right: 70px;
            .heading {
                width: 100%;
            }
        }
    }
    &.none-two-column {
        height: auto;
        .ctvd &{
            padding-bottom: $small-vertical-spacing / 2;
        }
        .text-container {
            @include col10;
            width: 100%;
            margin: 0 auto;
            display: grid ;
            grid-template-columns: [c1s] 1fr [c2s] 1fr [c2e];
            grid-template-rows: [r1s] auto [r2s] auto [r2e];
            column-gap: $spacer-4x;
            justify-content: center;
        }
        .icon-container {
            grid-column: c1s ;
            grid-row: r1s / r1s; 
            margin-bottom: $spacer-3x;
        }
        .heading {
            grid-column: c1s;
            grid-row: r2s / r2e ;
            @include H2;
            color: $heading-blue;
        }
        .paragraph {
            margin-left: 5px;
            @include H4;
            grid-column: c2s;
            grid-row: r1s / r2e;
            color: $dark-text;
        }
        @media(max-width: $min-md - 1) {
            .text-container {
                display:block;
            }
            .img-icon {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
            .heading {
                text-align: center;
            }
        }
    }
    &.right-side-image, &.left-side-image {
        margin-left: auto;
        margin-right: auto;
        .text-container {
            padding-top: $spacer-6x;
            @media(min-width: $min-xxl){
                padding-top: $spacer-10x;
            }
        }
        .heading {
            margin-bottom: 38px;
            @include H3;
            &:empty {
                display: none;
            }
        }
        .paragraph {
            @include Body;
        }
        .container {
            @include col10;
        }
        .img-container {
            display: flex;
            align-items: flex-start;
            object-fit: contain;
        }
        @media (min-width: $min-md) and (max-width: $min-lg) {
            .text-container {
                padding-top: 0;
                padding-left: $spacer-5x;
                padding-right: $spacer-5x;
            }
            .img-container {
                justify-content: center;
            }
        }
        @media(min-width: $min-lg){
            .img-container,
            .text-container {
                width: 50%;
            }
        }
    }
    &.left-side-image {
        .text-container {
            order: 2;
        }
        @media(max-width: $min-lg){
            .text-container {
                order: 1;
            }
            .img-container {
                order: 2;
            }
        }
    }
    &.below {
        .text-container {
            width: 100% !important;
            @include col10;
            margin: 0 auto;
            height: auto !important;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: [col1-start] 550px [col2-start] auto [col3-end];
            grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
            column-gap: 32px;
            justify-content: center;
            padding: 0;
        }
        .heading {
            grid-column: col1-start;
            grid-row: row1-start / row2-end;
         }
        .paragraph {
            @include H4;
            grid-column: col2-start;
            grid-row: row1-start;
        }
        .img-container {
            width: 100% !important;
            @include col10;
            z-index: 0;
            .cavd &, .ghdc & {
                margin-bottom: -($vertical-spacing * 4);
            }
            margin-bottom: -($vertical-spacing * 2);
            background-color: $background-gray;
            padding-left: 0px;
            padding-right: 0px;
            text-align: center;
        }
        .img-fluid {
            width: 100%;
        }
        .btn-filled {
            grid-column: col2-start;
            grid-row: row2-start;
        }
        @media(max-width: $min-lg){
            padding-top: 50px;
            .img-container,
            .text-container {
                width: 75%;
                display: block;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
            .heading, .paragraph, .about-link {
                margin-left: auto;
                margin-right: auto;
            }
            .paragraph {
                text-align: left;
            }
        }
        @media(max-width: $min-xl - 1){
            .img-container {
                .cavd &, .ghdc & {
                    margin-bottom: -($vertical-spacing * 3);
                }
                margin-bottom: -($small-vertical-spacing * 2);
            }
        }
    }
    &.above {
        margin-top: $vertical-spacing * 2;
        margin-bottom: $vertical-spacing;
        display: flex;
        flex-wrap: wrap-reverse;
        .text-container{
            margin-top: 40px;
            order: 2;
            width: 100%;
            @include col10;
            margin-left: auto;
            margin-right: auto;
        }
        .heading {
            @include H2;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
         }
        .paragraph {
            @include Body;
        }
        .img-container {
            text-align: center;
            max-height: 630px;
            width: 100%;
            order: 1;
            margin-bottom: 40px;
            margin-top: -($vertical-spacing * 2);
        }
        @media(max-width: $min-xl - 1){
            margin-top: $small-vertical-spacing;
            .img-container {
                margin-top: -($small-vertical-spacing * 2);
            }
        }
    }
   
    //text alignment
    &.left{
        .paragraph {
            text-align: left;
        }
    } 
    &.centered{
        .paragraph {
            text-align: center;
        }
    }
    &.right{
        .paragraph {
            text-align: right;
        }
    } 
    @media(max-width: $min-md) {
        max-width: 100%;
    }
}
.navygradient {
    .text-container {
        color: $white;
    }
}
.text-container {

    .heading {
        @include H2;
        margin-bottom: 18px;
    }
    .paragraph {
        @include H4;
        margin-bottom: 32px;
    }
    .cta {
        @include TextCTA;
    }
}