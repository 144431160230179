@import '../vars.scss';

.content-tile-banner {
    @include verticalSpacing;
    &.centered {
        .container {
            .tile-container{                
                text-align: center;
                .heading{
                    @include H2;
                    position: relative;
                    color: $heading-blue;
                    &:empty {
                        margin: 0;
                    }
                }
                .banner-link{
                    align-items: center;
                }
                & > .paragraph {
                    @include H4;
                    @include col8;
                    margin: 0 auto $vertical-spacing;
                }
            }
        }
    }
    &.left-image {
        .container {  
            @media(max-width: $min-lg) {                
                .img-container {
                    display:block;                 
                }
                .tile-container{
                    display: block;
                    text-align: center;
                }
            } 
            @media(min-width: $min-lg) { 
                max-width: none;
                width: 100%;      
                display: flex;  
                .img-container {
                    order: 1;
                    width: 50%;
                }
                .tile-container{
                    order: 2;
                    padding-left: 40px;
                    width: 50%;
                    .col {
                        width: 100%;
                    }
                }
            }
            @media(min-width: $min-xl){
                .img-container {
                    width: 60%;
                    margin-left: -16px;
                }
                .tile-container {
                    width: 40%;
                }
                .banner-link{
                    margin-left: 35px;
                }
            }
            @media (min-width: $min-xxl) {
                max-width: 1440px;
            }
        }
    }
    &.left-text-only {
        .tile-container {
            position: relative;
        }
        .col {
            margin-bottom: $spacer-4x;
            display: flex;
            justify-content: center;
        }
        .heading {
            margin-bottom: 50px;
            @include H2;
            color: $heading-blue;
        }
        .subheading {
            margin-bottom: 30px;
            font-size: 24px;
            color: $heading-blue;
        }
        .banner-link {
            position: absolute;
            top: 0;
            right: 0;
        }
        @media(max-width:767px) {
            display: block;
            text-align: center;
            .banner-link {
                position: unset;
            }
        }
    }
    &.no-text {
        .col {
            margin-bottom: $spacer-4x;
            display: flex;
            justify-content: center;
        }
    }
    &.bg-image {
        .heading {
            @include H2;
            //color: $heading-blue;
        }
        .tile-container > .heading {
            @media(min-width: $min-lg){
                margin-top: 175px;
            }
        }
        .paragraph {
            width: 50%;
            @include H4;
            margin-bottom: $spacer-8x;
            color: $dark-text;
        }
        @media (max-width: $min-sm) {
            .row {
                margin-bottom: $spacer-4x;
            }
        }
        @media (max-width: $min-lg) {
            background: none !important;
            .paragraph {
                width: 90%;
            }
            .col {
                margin-bottom: $spacer-4x;
            }
        }
    }
    .row-cols-xl-2 {
        .large-image .img-fluid {
            height: 260px;
        }
    }

}