@import '../vars.scss';

.carousel-banner-container{
    width: 100%;
}

.carousel-banner {
    background-color: $background-gray;
    @include verticalSpacing;
    .heading {
        @include H2;
        color: $blue-80;
        text-align: center;
    }
    .text-container {
        color: $dark-text;
        width: 100%;
    }
    .btn-default {
        @include TextCTA;
    }
    .carousel-tile {
        margin-bottom: 32px;
        padding: 0 16px 32px;
    }
    .carousel-row {
        margin-top: 24px;
    }
    .btn-container {
        margin-bottom: 24px;
    }
    @media(min-width: $min-lg){
        .btn-container {
            text-align: right;
        }
    }
}

.dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}
  
.dot {
    border: none;
    width: 18px;
    height: 18px;
    background: $ui-gray;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;
    &:only-child {
        display: none;
    }
}
  
.dot.active {
    background: $green-60;
}