@import '../vars.scss';

.modal-body {
    .indented {
    text-indent: 3%;
    }
    .bullet-list {
        li {
            font-size: 110%;
        }
        li:last-of-type {
            margin-bottom: $spacer-3x;
        }
    }
    p {
        font-size: 110%;
    }
    h5 {
        color: $blue-80;
    }
}

.modal-header {    
    .bi-x-lg {
        border: unset;
        background-color: unset;
        margin-bottom: auto;
    }

}