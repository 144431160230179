@import '../_vars.scss';


.list-container {
    padding-top: $spacer-4x;
    padding-bottom: $spacer-12x;
}

.nav-list {
    background: $dark-background-gray;
    a {
        color: $dark-text;
        text-decoration: none;
        padding: 6px;
        display: inline-block;
        margin: 5px 15px;
        &:first-child{
            margin-left: 0;
        }
        &:hover, &:active, &:focus {
            @include hoverBold($dark-text);
            border-bottom: 2px solid #263877;
            padding-bottom: 4px;
        }
    }
}

.link-grouping {
    padding-top: $spacer-8x;
    color: $blue-80;
    h2 {
        @include H3;
        font-weight: bold;
        margin-bottom: $spacer-3x;
    }
    h3, a {
        @include TextLink;
        color: $blue-80;
        display: block;
    }
    a, p {
        margin-bottom: 0.25rem;
    }
}