/*FONTS*/
$font-sans: noto-sans, sans-serif;

@mixin B1 {
    font-weight: bold;
    font-size: 48px;
    @media(min-width: $min-xxl){
        font-size: 52px;
    }
}

@mixin H2 {
    font-weight: normal;
    font-size: 36px;
    @media(min-width: $min-xxl){
        font-size: 48px;
    }
}

@mixin B2 {
    font-weight: 300;
    font-size: 24px;
    @media(min-width: $min-xxl){
        font-size: 32px;
    }
}

@mixin H3 {
    font-weight: 600;
    font-size: 20px;
    @media(min-width: $min-xxl){
        font-size: 24px;
    }
}

@mixin H4 {
    font-weight: normal;
    font-size: 20px;
    @media(min-width: $min-xxl){
        font-size: 24px;
    }
}

@mixin H5 {
    font-weight: 500;
    font-size: 18px;
    @media(min-width: $min-xxl){
        font-size: 20px;
    }
}

@mixin Body {
    font-weight: normal;
    font-size: 16px;
    @media(min-width: $min-xxl){
        font-size: 18px;
    }
}

@mixin TextLink {
    font-weight: 500;
    text-decoration: underline;
    font-size: 16px;
    @media(min-width: $min-xxl){
        font-size: 18px;
    }
}

@mixin TextCTA {
    font-weight: bold;
    font-size: 16px;
    @media(min-width: $min-xxl){
        font-size: 18px;
    }
}

@mixin NavText {
    font-weight: normal;
    font-size: 16px;
}

/*COLORS*/

$white: #ffffff;
$background-gray: #f7f7f7;
$background-grey: $background-gray;
$dark-background-grey: #F4F4F4;
$dark-background-gray: $dark-background-grey;
$light-ui-gray: #D0D0D0;
$ui-gray: #bababa;
$border-gray: #a6a6a6;
$ui-grey: $ui-gray;
$pull-quote-gray: #8c8c8c;
$pull-quote-grey: $pull-quote-gray;
$caption: #6b6b6b;
$dark-text: #3C3C3C;
$darker-text: #333;
$background-black: #1d1c1c; 
$text-black: #000000;
$teal-green: #038588;
$sp-blue: #004E8C;

$blue-0: #f2fbff;
$blue-5: #c8ebfe;
$blue-10: #a4dcf9;
$blue-20: #81c9f3;
$blue-30: #63b3e8;
$blue-40: #4b98d9;
$blue-50: #3579C5;
$blue-60: #2658ab;
$blue-70: #1b3c8e;
$blue-80: #12236d; 
$blue-90: #0C184A;
$blue-100: #070d27;
$heading-blue: #1C2A62;
$green-0: #f4fbf7;
$green-5: #d7efe0;
$green-10: #b9e4cf;
$green-20: #9cd9c5;
$green-30: #7ECDB4;
$green-40: #61c2aa; 
$green-50: #4fab9c;
$green-60: #428f8e; 
$green-70: #357372; 
$green-80: #295958;
$green-90: #1b3b3b; 
$blue-gray: #506993;
$blue-grey: $blue-gray;
$purple-60: #9c3573; 
$red: #EB0C42;

$navy-gradient: linear-gradient($blue-90, $blue-60);

/*BREAKPOINTS*/

$min-sm: 576px;
$min-md: 768px;
$min-lg: 992px;
$min-xl: 1280px;
$min-xxl: 1400px;

/*MIXINS*/

@mixin hoverBold($color){
    text-shadow: 0 0.015em $color,0 -0.015em $color,0.01em 0 $color,-0.01em 0 $color;
}

/*BASE*/

.italics {
    font-style: italic;
}

/*SPACING*/

$spacer-1x: 8px;
$spacer-2x: 16px;
$spacer-3x: 24px;
$spacer-4x: 32px;
$spacer-4-5x: 36px;
$spacer-5x: 40px;
$spacer-6x: 48px;
$spacer-7x: 56px;
$spacer-8x: 64px;
$spacer-9x: 72px;
$spacer-10x: 80px;
$spacer-11x: 88px;
$spacer-12x: 96px;

$small-vertical-spacing: $spacer-4-5x;
$vertical-spacing: $spacer-6x;

@mixin verticalSpacing {
    padding-top: $small-vertical-spacing;
    padding-bottom: $small-vertical-spacing;
    @media(min-width: $min-xl){
        padding-top: $vertical-spacing;
        padding-bottom: $vertical-spacing;
    }
    .ghdc &{
        padding-top: $spacer-6x ;
        padding-bottom: $spacer-6x;
        @media(min-width: $min-xl){
            padding-top: $spacer-12x;
            padding-bottom: $spacer-12x;
        }
    }
}

@mixin col8 {
    margin: 0 auto;
    max-width: 744px;
    @media(min-width: $min-xxl){
        max-width: 896px;
    }
}

@mixin col10 {
    margin: 0 auto;
    max-width: 1084px;
    @media(min-width: $min-xxl){
        max-width: 1128px;
    }
}
//col-12 max widths uses the .container class from bootstrap