.labelTop {
    color: white;
    font-size: small;
    white-space: nowrap;
    position: fixed;
    right: 15px;
    bottom: 95px;

}

.iconTop {
    color:white;
    font-size: 80px;
    white-space: nowrap;
    position: fixed;
    right: 20px;
    bottom: 0px;

}

.scrollToTop {

    position: fixed;
    right: 10px;
    bottom: 40px;
    width: 100%;
    min-width: auto;
    max-width: 70px;
    height: 80px;
    // font-size: 3rem;
    z-index: 2;
    cursor: pointer;
    background-color: #004f8bc6;
    color: white;
    border-radius: 8px;
    border: none;
    font-weight: bolder;

}

.scrollToTop:hover {

    background-color: #004f8b !important;


}