@import '../vars.scss';

//font designs
.publication-tile-banner {
    &.centered {
        .container {
            .tile-container .heading{
                color: $heading-blue;
            }
        }
    }
    &.no-text {
        @include col10;
    }
}
