@import '../vars.scss';

.wide-carousel-banner-container{
    width: 100%;
}

.wide-carousel-banner {   
    @include verticalSpacing;
    @media(min-width: $min-lg){
        .btn-container {
            text-align: right;
        }
    }
    .wide-carousel-tile {  
        @media (min-width: 1440px) {
            height: 538px;
        }
        @media (max-width: 1440px){
            height: 450px;
        }
        @media (max-width: $min-xl){
            height: 398px;
        }
        @media (max-width: 1000px){
            height: 373px;
        }
        @media (max-width: $min-lg){
            height: 299px;
        }
        @media (max-width: 800px){
            height: 287px;
        }
        @media (max-width: $min-md){
            height: 224px;
        }
        @media (max-width: $min-sm) {
            max-height: 214px;
        }        
        @media (max-width: 475px) {
            max-height: 170px;
        }    
        @media (max-width: 400px) {
            max-height: 160px;
        }
        @media (max-width: 350px) {
            max-height: 130px;
        }
        @media (min-width: 250px) and (max-width: 300px){
            max-height: 100px;
        }
    }
    .container {
        position: relative;
        padding: 0px 0px;   
        width: 90%;     
    }
    
    .dots {
        display: flex;
        margin-left: 27px;
        position: relative;
        bottom: 50px;
        justify-content: left;
        
        @media (max-width: $min-lg) {
            bottom: 30px;
            margin-left: 17px;
        }
        @media (max-width: $min-sm) {
            margin-left: 3px;
        }
    }    
    .dot {
        border: none;
        width: 10.7px;
        height: 10px;
        padding: 5px;
        background: $ui-gray;
        border-radius: 100%;
        margin: 0 6px;
        cursor: pointer;
        &:only-child {
            display: none;
        }
    }    
    .dot.active {
        background: $green-60;
    }

    .arrow {
        position: absolute;
        right: 0;
        top: 40%;
        color: white;
        font-size: 30px;
        cursor: pointer;
        opacity: 0.5;  
        &:hover {
            opacity: 0.8;
        }
        @media (max-width: $min-md) {
            font-size: 20px;
        }        
        @media (max-width: $min-sm) {
            font-size: 15px;
        }
        @media (max-width: 400px) {     
            font-size: 10px;
        }
        &:before {
            padding: 15px;
            background: $background-black;  
            @media (max-width: $min-xl) {
                padding: 8px;
            }
            @media (max-width: $min-lg) {
                padding: 6px;
            } 
            @media (max-width: $min-sm) {
                padding: 5px;
            } 
            @media (max-width: 400px) {
                padding: 5px;
            }          
        }
      }      
      .arrow--left {
        left: 0;
        width: 0px;
      } 
}