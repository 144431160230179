@import '../vars.scss';

.rich-text-banner-container + .rich-text-banner-container .rich-text {
    padding-top: 0;
}

.rich-text {
    @include verticalSpacing;
    .text-container {
        color: #3C3C3C;
        letter-spacing: 0px;
        @include Body;
        @include col10;
        width: 100%;
        margin: 0 auto;
        h2 {
            @include H2;
        }
        h4 {
            @include H3;
        }
        h4, h2 {
        color: $heading-blue;
        margin-bottom: 20px;
            a {
                color: $heading-blue;
            }
        }
        img {
            width: 828px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        hr {
            margin: $spacer-12x 0 $spacer-4x;
        }
    }
    &.gray {
        background-color: $background-gray;
    }
    &.white {
        background-color: white;
    }
    &.link-to-btn {
        p {
            margin-top: 50px;
        }
        h2, h4 {
            margin-bottom: -30px;
        }
    }
    &.bio-pic-sizing {
        img {
            height: 180px;
            width: 126px;
            object-fit: cover;
        }
    }
    &.link-no-underline {
        a {
            text-decoration: none;
            color: $blue-70;
        }
    }
    &.image-centered {
        img {
            display: block;  
            margin-left: auto;  
            margin-right: auto; 
        }
    }
}