@import '../vars.scss';

.footer-content{
    @include Body;
    @include col10;  
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .footer-content {
        font-size: 12px;
    }
   
}

/* Adjust font size for even smaller screens */
@media screen and (max-width: 480px) {
    .footer-content {
        font-size: 10px;
    }
}