@import '../_vars.scss';

.sub-paragraph {
    color: $dark-text;
    font-size: 16px;
    font-weight: 300;
    margin-top: 32px;
    padding: 0;
    a {
        color: $blue-80;
    }
}

.filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    @media(max-width: $min-md - 1){
        justify-content: center;
        .sort {
            margin-right: 0;
        }
        .search {
            height: 34.8px;
        }
        .dropdown-toggle {
            width: 316px;
        }
        .search-container{ 
            margin-top: 12px;
        }
    }
}

.results {
    &:empty {
        height: 40px;
    }
    @media(max-width: $min-sm){
        max-width: 475px;
    }
    @media(max-width: 475px){
        max-width: 400px;
    }
    @media(max-width: 400px){
        max-width: 320px;
    }
    & > .loading{
        margin-top: 40px;
        width: 100%;
    }
}

.container-loading {
    .no-results {
        display: none;
    }
}

.loading {
    text-align: center;
    margin-bottom: 80px;
}