@import '../vars.scss';


.three-image {
    .container{
        @include verticalSpacing;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        .heading{
            padding-bottom: 50px;
            @include H2;
        }
        .paragraph {
            @include H4;
        }
        .image-container {
            display: flex;
            align-items: center;
            margin: 0 auto;
            .image-grid {
                .image-0 { grid-area: image0; padding-top:110px; }
                .image-1 { grid-area: image1; }
                .image-2 { grid-area: image2; }
                display: grid;
                grid-template-areas: 'image0 image1' 'image0 image2';
                grid-gap: 30px;
            }
        }
        @media(min-width: $min-sm) { 
            .text-container {
                width: 40%;                
                @media(max-width: $min-lg - 1){
                    width: 100%;
                }
            }
        } 
        @media(min-width: $min-lg){
            .text-container {
               padding-top: $spacer-12x;
            }
            .image-container {
                margin: 0;
                width: 50%;
            }
        }
        @media(max-width: $min-sm) {                            
            .text-container {
                text-align: center;
                padding-bottom: 30px;
                padding-top: 175px;
            }
        }
    }
     //background colors
     &.gray {
        background-color: $background-gray;
        .heading {
            color: $blue-gray;
        }
        .paragraph {
            color: black;
        }
    }
    &.white {
        background-color: white;
        .heading {
            color: $blue-gray;
        }
        .paragraph {
            color: black;
        }
    }
    &.navygradient {
        background: $navy-gradient;
        a {
            border-color: white;
            color: white;
        }
    }
}