@import '../vars.scss';

.three-image {
    &.gray {
        background-color: $background-gray;
        .heading {
            color: $heading-blue;
        }
        .paragraph {
            color: black;
        }
    }
    &.white {
        background-color: white;
        .heading {
            color: $heading-blue;
        }
        .paragraph {
            color: black;
        }
    }
    &.navygradient {
        background: $navy-gradient;
        a {
            border-color: white;
            color: white;
        }
    }
}