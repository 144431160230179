@import '../_vars.scss';

.pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: $spacer-8x 0 $spacer-12x;
    font-size: 24px;
    button {
        border: none;
        background: transparent;
        color: $darker-text;
        margin: 0 12px;
        &[disabled]{
            color: $ui-gray;
        }
    }
}