@import '../vars.scss';
.arrow-container{ 
    padding-bottom: 100px;
    a {
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
    }    
    .arrow-title{
        color: $blue-80;
        font-size: 40px;
        font-weight: lighter;
    }
    .bi {
        color: $teal-green;
        font-size: 55px;
    }
    .left{
        float: left;
    }
    .right{
        float: right;
    }
    .arrow-tag {
        font-size: 18px;
        color: $dark-text;
        font-weight:bold;
        margin: -20px;
    }
    .arrow-tag.left{
        padding-left: 75px;
    }
    .arrow-tag.right{
        padding-right: 85px;
    }

    @media (max-width: $min-lg) {
        padding-top: 50px;
        .arrow-title{
            font-size: 20px;
        } 
        .arrow-tag {
            font-size: 10px;
            margin: -10px;
        }
        .bi {
            font-size: 35px;
        }
    }  
}

@media(min-width: $min-lg){
    .arrowLink-container + .arrowLink-container {
        margin-top: -100px;//update with final pixel value of component height
    }
    .arrow-left{
       width: 50%;
       margin-right: 500px;
    }
    .arrow-right{
       width: 50%;
       margin-left: 500px;
    }
}

@media (max-width: $min-lg) {
    .arrowLink-container + .arrowLink-container {
        margin-top: -150px;
    }
    .arrow-left{
        width: 50%;
        margin-right: 300px;
     }
     .arrow-right{
        width: 50%;
        margin-left: 300px;
     }
}

.arrowLink-container {
    &:last-child {
        padding-bottom: 70px;
        @media(min-width: $min-lg){
            padding-bottom: 200px;
        }
    }
}