/*Bootstrap variable overrides*/
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1084px,
  xxl: 1360px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 993px,
  xl: 1280px,
  xxl: 1440px
);

$grid-gutter-width: 32px;