@import '../_vars.scss';

footer .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 35px;
    color: $dark-text;
    p {
        font-weight: 400;
        margin-bottom: 0.5rem;
    }
}
.footer-brand{
    flex-grow: 0;
    margin-bottom: 80px;
    p {
        display: inline-block;
        @include Body;
    }
    img {
        display: block;
        height: 100%;
        width: 300px;
        @media(max-width: $min-sm +1){
            width: 200px;
        }
    }
}

.footer-bottom {
    text-align: center;
    a {
        font-weight: 600;
        color: $dark-text;
        text-decoration: none;
        &:hover {
            color: $dark-text;
            text-decoration: underline;
        }
    }
    span {
        font-weight: 800;
        margin: 0 4px;
    }
}