/*imports*/
@import url("https://use.typekit.net/tlr3yqs.css"); // noto sans fonts
@import './styles/banner.scss';
@import './styles/contentTileBanner.scss';
@import './styles/publicationTileBanner.scss';
@import './styles/contentTile.scss';
@import './styles/threeImageBanner.scss';
@import './styles/imageBanner.scss';
@import './styles/buttons.scss';
@import './styles/navigation.scss';
@import './styles/carouselBanner.scss';
@import './styles/richTextBanner.scss';

body {
    font-family: $font-sans;
    font-size: 18px;
}