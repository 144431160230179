@import '../vars';

.btn-default { 
    background-color: $teal-green;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    &:hover {
        color: white;
    }
}
.btn-filled, .link-to-btn a {
    text-align: center;
    text-decoration: none;
    padding: 12px 34px;
    display: inline-block;
    min-height: 48px;
    background-color: $teal-green;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    &:hover {
        color: white;
    }
}
.text-only { 
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    color: $teal-green;
}
