@import '../vars.scss';

.json-container{
    &:last-child {
        hr {
            display: none;
        }
    }
}

.jsonList {
    @include verticalSpacing;
    width: 100%;
    @include col10;
    hr {
        margin-top: 70px;
        opacity: 1;
        background-color: $light-ui-gray;
    }
}

.jsonListItem {
    margin-top: 35px;
    h2 {
        @include H3;
        color: $blue-80;
    }
    h3 {
        @include Body;
        a {
            @include TextLink;
            color: $blue-80;
        }
    }
    p, h3 {
        margin-bottom: 0;
        line-height: 28px;
    }
}