.cookie-modal {
    visibility: hidden;
}

.cookie-message {
    background-color: #222222;
    border: 8px solid #fdfdfd;
    box-sizing: border-box;
    color: #fdfdfd;
    font-family: ff-din-web, "Helvetica Neue", "Segoe UI", Arial, freesans, sans-serif;
    font-size: 16px;
    line-height: 1.25;
    padding: 30px;
    position: fixed;
    bottom: 0;
    left: 50%;
    max-width: 900px;
    text-align: center;
    transform: translate(-50%);
    width: 100%;
    z-index: 100;
    visibility: visible;
   }
  
  .cookie-message__title {
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase; }
  
  .cookie-messages__body {
    margin: auto;
    margin-bottom: 16px;
    max-width: 700px; }
    .cookie-messages__body a {
      // color: #fdfdfd;
      text-decoration: underline; }
      .cookie-messages__body a:hover {
        color: #fdfdfd; }
  
  .cookie-message__button {
    background-color: #3587b1;
    border: none;
    color: #fdfdfd;
    font-size: 14px;
    padding: 14px;
    text-decoration: underline; }

