@import '../vars.scss';

.wide-content-tile {
    &.over-image {
        height: 100%;
        >a {
            text-decoration: none;
        }
        .image-container {
            display: flex;
            height: 100%;
        }
        .img {      
            flex-shrink: 0;
            width: 100%;
            object-fit: cover;
        }
        .tile-text-container {
            text-align: left;
            padding-left: 30px;
            padding-bottom: calc(35px + 1.5rem);
            padding-top: 60px;
            display: flex;
            flex-direction:column;
            width: 100%;
            
            color: white; 

            position: fixed;
            bottom: 0;
            left: 0;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.15), rgba(0,0,0,0.3), rgba(0,0,0,0.5), rgba(0, 0, 0,0.7),rgba(0, 0, 0,0.85), rgba(0, 0, 0,0.95), rgb(0, 0, 0)); 
                opacity: 0.9;
                z-index: -1;
            }
            @media (max-width: $min-lg) {
                padding-top: 40px;
                padding-left: 24px;
                padding-bottom: calc(10px + 1.5rem);
            }
            @media (max-width: $min-md) {
                padding-bottom: calc(1.5rem);
            }
            @media (max-width: $min-sm) {
                padding-top: 20px;
                padding-left: 10px;
                padding-bottom: calc(5px + 1.5rem);
            }
            .tile-heading  {
                position: relative;
                font-weight: 400;
                width: 100%;
                @media (max-width: $min-xl) {
                    font-size: 18px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 14px;
                    margin-bottom: 4px;
                }
                @media (max-width: $min-md) {
                    font-size: 10px;
                }                
                @media (max-width: 500px) {
                    width: 80%;
                    margin-bottom: 0px;
                }
            }
            .paragraph{              
                font-weight: 500;
                font-size: 16px;
                color: white; 
                width: 100%;
                p {
                    margin-bottom: 0px;
                }
                @media (max-width: $min-xl) {
                    font-size: 12px;  
                }
                @media (max-width: $min-lg) {
                    font-size: 8px;
                }
                @media (max-width: $min-md) {
                    font-size: 6px;
                }
                @media (max-width: 500px) {
                    font-size: 5px;
                    width: 80%;
                }
            }
        }
    }
    &.text-only{
        .tile-text-container {
            text-align: left;
            display: flex;
            justify-content: center;
            flex-direction:column;
            width: 100%;
            height: 300px;
            margin-left: 10px;
            margin-right: 10px;
            
            @media (max-width: $min-lg) {
                height: 300px;
            }
            @media (max-width: $min-md) {
                height: 200px;
            }
            @media (max-width: $min-sm) {
                height: 100px;
            }
            .tile-heading {
                &:after {
                    content: '';
                    width: 100%;
                    height: 3px;
                    background: black;
                    position: absolute;
                    left: 0;
                    top: 20px;
                }
                color: $blue-80; 
                position: relative;
                font-weight: 800;
                width: 100%;
                padding-bottom: 5px;
                @media (max-width: $min-xl) {
                    font-size: 18px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 14px;
                    margin-bottom: 4px;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: black;
                        position: absolute;
                        left: 0;
                        top: 15px;
                    }
                }
                @media (max-width: $min-md) {
                    font-size: 10px;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: black;
                        position: absolute;
                        left: 0;
                        top: 10px;
                    }
                }                
                @media (max-width: 500px) {
                    width: 100%;
                    margin-bottom: 0px;
                }
            }
            .tile-subheading{
                @media (max-width: $min-xl) {
                    font-size: 16px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 12px;
                    margin-bottom: 4px;
                }
                @media (max-width: $min-md) {
                    font-size: 8px;
                }                
                @media (max-width: 500px) {
                    width: 80%;
                    margin-bottom: 0px;
                }
            }

            .paragraph{              
                font-weight: 500;
                font-size: 16px;
                color: black; 
                width: 100%;
                p {
                    margin-bottom: 0px;
                }
                @media (max-width: $min-xl) {
                    font-size: 12px;  
                }
                @media (max-width: $min-lg) {
                    font-size: 8px;
                }
                @media (max-width: $min-md) {
                    font-size: 6px;
                }
                @media (max-width: 500px) {
                    font-size: 5px;
                    width: 100%;
                }
            }
        }
        .image-container {
            display: none;
            height: 100%;
        }

    }
    &.left-side-image{
        display: flex;
        flex-direction: row;
        height: 100%;
        .tile-text-container {
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            margin-left: 10px;
            margin-right: 10px;

            .tile-heading {
                &:after {
                    content: '';
                    width: 100%;
                    height: 3px;
                    background: black;
                    position: absolute;
                    left: 0;
                    top: 20px;
                }
                color: $blue-80; 
                font-size: 18px;
                position: relative;
                font-weight: 800;
                width: 100%;
                padding-bottom: 5px;
                @media (max-width: $min-xl) {
                    font-size: 18px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 14px;
                    margin-bottom: 4px;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: black;
                        position: absolute;
                        left: 0;
                        top: 15px;
                    }
                }
                @media (max-width: $min-md) {
                    font-size: 10px;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: black;
                        position: absolute;
                        left: 0;
                        top: 10px;
                    }
                }                
                @media (max-width: 500px) {
                    width: 100%;
                    margin-bottom: 0px;
                }
            }
            .tile-subheading{
                font-size: 16px;  
                @media (max-width: $min-xl) {
                    font-size: 16px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 12px;
                    margin-bottom: 4px;
                }
                @media (max-width: $min-md) {
                    font-size: 8px;
                }                
                @media (max-width: 500px) {
                    width: 80%;
                    margin-bottom: 0px;
                }
            }
            .paragraph{              
                font-weight: 500;
                font-size: 12px;
                color: black; 
                width: 100%;
                p {
                    margin-bottom: 0px;
                }
                @media (max-width: $min-xl) {
                    font-size: 12px;  
                }
                @media (max-width: $min-lg) {
                    font-size: 8px;
                }
                @media (max-width: $min-md) {
                    font-size: 6px;
                }
                @media (max-width: 500px) {
                    font-size: 5px;
                    width: 100%;
                }
            }
        }
        .image-container {
            .img{
                width: 300px;
                height: 100%;
                object-fit: cover;
            } 
            @media (max-width: $min-xl) {
                .img{
                    width: 300px;
                    height: 100%;
                    object-fit: cover;
                }                 
            }
            @media (max-width: $min-lg) {
                .img{
                    width: 200px;
                    height: 100%;
                    object-fit: cover;
                }
            }
            @media (max-width: $min-md) {
                .img{
                    width: 100px;
                    height: 100%;
                    object-fit: cover;
                }
            }                
            @media (max-width: 500px) {
                .img{
                    width: 70px;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
        }
    }
    &.right-side-image{
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        .tile-text-container {
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            margin-left: 10px;
            margin-right: 10px;

            .tile-heading {
                &:after {
                    content: '';
                    width: 100%;
                    height: 3px;
                    background: black;
                    position: absolute;
                    left: 0;
                    top: 20px;
                }
                color: $blue-80; 
                font-size: 18px;
                position: relative;
                font-weight: 800;
                width: 100%;
                padding-bottom: 5px;
                @media (max-width: $min-xl) {
                    font-size: 18px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 14px;
                    margin-bottom: 4px;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: black;
                        position: absolute;
                        left: 0;
                        top: 15px;
                    }
                }
                @media (max-width: $min-md) {
                    font-size: 10px;
                    &:after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: black;
                        position: absolute;
                        left: 0;
                        top: 10px;
                    }
                }                
                @media (max-width: 500px) {
                    width: 100%;
                    margin-bottom: 0px;
                }
            }
            .tile-subheading{
                font-size: 16px;  
                @media (max-width: $min-xl) {
                    font-size: 16px;                    
                }
                @media (max-width: $min-lg) {
                    font-size: 12px;
                    margin-bottom: 4px;
                }
                @media (max-width: $min-md) {
                    font-size: 8px;
                }                
                @media (max-width: 500px) {
                    width: 80%;
                    margin-bottom: 0px;
                }
            }
            .paragraph{              
                font-weight: 500;
                font-size: 12px;
                color: black; 
                width: 100%;
                p {
                    margin-bottom: 0px;
                }
                @media (max-width: $min-xl) {
                    font-size: 12px;  
                }
                @media (max-width: $min-lg) {
                    font-size: 8px;
                }
                @media (max-width: $min-md) {
                    font-size: 6px;
                }
                @media (max-width: 500px) {
                    font-size: 5px;
                    width: 100%;
                }
            }
        }
        .image-container {
            .img{
                width: 300px;
                height: 100%;
                object-fit: cover;
            } 
            @media (max-width: $min-xl) {
                .img{
                    width: 300px;
                    height: 100%;
                    object-fit: cover;
                }                 
            }
            @media (max-width: $min-lg) {
                .img{
                    width: 200px;
                    height: 100%;
                    object-fit: cover;
                }
            }
            @media (max-width: $min-md) {
                .img{
                    width: 100px;
                    height: 100%;
                    object-fit: cover;
                }
            }                
            @media (max-width: 500px) {
                .img{
                    width: 70px;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
        }
    }
    a > .text-only {
        &:hover{
            text-decoration: underline;
        }
    }
}