@import '../vars.scss';

.accordion-container{
    @include verticalSpacing;
    .btn-link {
        text-decoration: none;
        width: 100%;
        text-align: left;
        @include H5;
    }

    @media(max-width: $min-xl) {
        width: 100%;
    }
}

.card-body {
    padding-left: 40px;
}

