@import '../vars.scss';

.content-tile {
    &.big-heading {
        .container {            
            .text-container {
                .heading {
                    color: $heading-blue;
                }
            }
        }
    } 
    &.image-no-shadow {
        .container {
            .text-container {
                .heading {
                    color: $heading-blue;
                }
                .subheading {
                    color: $dark-text;
                    font-weight: 400;
                }
                .paragraph:empty {
                    display: none;
                }
            }
        }
    }
    &.small-image {  
        .tile-text-container .tile-heading {
            color: $heading-blue;
        }
    }
}