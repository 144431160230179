 .banner-container{
    width: 100%;
}
.content-tile-banner-container {
    width: 100%;
}
.publication-tile-banner-container {
    width: 100%;
}
.three-image-container {
    width: 100%;
}
.tabbed-list-container{
    width: 100%;
}
.image-banner-container {
    width: 100%;
}
.rich-text-banner-container {
    width: 100%;
}
.arrowLink-container {
    width: 100%;    
} 

.accordion-list-container {
    width: 100%;
}

.search-layout-container {
    width: 100%;
}

.sharepointContentBanner-container {
    width: 100%;
}