@import '../vars';

.nav-link {
    span {
        color: $heading-blue;
    }
    &.active, &:hover {
        span {
            @include hoverBold($heading-blue);
            border-color: $teal-green;
        }
    }
}