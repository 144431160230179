@import '../_vars.scss';

.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
    background: white;
    position: absolute;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.nav-link {
    margin: 0 4px;
    span {
        color: #263877;
        display: inline-block;
        padding-bottom: 8px;
    }
    &.active, &:hover {
        span {
            border-bottom: 2px solid #263877;
            padding-bottom: 6px;
        }
    }
    &.dropdown-item {
        margin-left: 0;
        &.active{ 
            background-color: transparent;
        }
        &:active, &:focus {
            background-color: $background-gray;
        }
    }
}

.navbar-collapse > .nav-link:nth-child(2){
    margin-left: auto;
}

.navbar-brand img{
    width: 160px;
    height: 60px;
}

.navbar {
    @include NavText;
    min-height: 98px;
    .btn-default {
        @include TextCTA;
        padding: 12px 34px;
        min-width: 150px;
        margin-left: 20px;
        text-decoration: none;           
        display: inline-block;
        text-align: center;
    }
    .navbar-toggler {
        font-size: 32px;
        border: 0;
    }    
}

.bi-question-circle{
    margin-left: 5px;
}

.dropdown-toggle {
    background: transparent;
}

.dropdown-button{
    border: none;
    background-color: transparent;
}

.dropdown-multicol{
    display: flex;
}

.dropdown-multicol>div.dropdown-col{
    width: 60%;
}

@media (max-width: $min-lg + 1) {
    .navbar {
        .dropdown {
            .nav-link {
                margin-right: 0;
                width: calc(100% - 84px);
                display: inline-block;
                &.dropdown-item{
                    padding-left: 2rem;
                    width: 100%;
                }
                span {
                    white-space: normal;
                }
            }
            .dropdown-button {
                margin-right: 24px;
            }
        }
        .dropdown-menu {
            position: relative;
            border: 0;
        }
        .btn-default {
            margin-top: 1rem;
        }
    }
    .navbar-collapse {
        position: absolute;
        top: 100%;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        background: white;
        width: 100%;
        padding: 1rem 0.5rem;
        z-index: 1;
    }

    .dropright{
        .dropdown-button{
            margin-left:5px
        }
        .nav-link{
            padding-left:32px;
        }
        .dropdown-menu{
            padding-left:32px;
        }
    }
    
}

@media (min-width: $min-lg){
    .navbar .navbar-collapse{
        margin-left: auto;
        flex-grow: 0;
    }
    .centerBrand {
        .navbar-collapse {
            margin-left: 0;
            flex-grow: 1;
            justify-content: space-between;
            .nav-link, .dropdown, .btn-default, .bi{
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        order: #{$i};
                    }
                }
            }
        }
        
    }
    .navbar .dropdown-menu {
        left: 1rem;
        border: 1px solid $ui-gray;    
        box-shadow:  0px 6px 12px #00000029;
        border-radius: 0;
        padding: 0;
        &.last-dropdown-menu {
            left: auto;
            right: 0;
        }
    }

    .dropright {
        .dropdown-menu {
            top: 0;
            right: auto;
            left: 100%;
            margin-top: 0;
          }
    }
  
}

.dropright {
    .nav-link{
        margin: 0;
    }
}

