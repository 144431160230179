@import '../vars.scss';

//font and positioning
.banner {
    &.none-one-column {
        .heading {
            color: $heading-blue;
        } 
    }
    &.gray, &.white {
        .heading{
            color: $heading-blue;
            &::before {
                display: none;
            }
        } 
    }
}

//Background colors
.banner{
    &.gray {
        background-color: $background-gray;
        .heading {
            color: $heading-blue;
        }
        .paragraph {
            color: black;
        }
    }
    &.white {
        background-color: white;
        .heading {
            color: $heading-blue;
        }
        .paragraph {
            color: black;
        }
    }
    &.navygradient {
        background: $navy-gradient;
        a {
            border-color: white;
            color: white;
        }
        .heading {
            color: white;
        }
        .paragraph {
            color: white;
        }
    }
}