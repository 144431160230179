@import '../_vars.scss';

.results-table{
    font-size: 14px;
    margin-top: $spacer-4x;
    th,td,& > :not(:last-child) > :last-child > * {
        border-bottom-color: $ui-gray;
    }
    td {
        padding: $spacer-3x $spacer-2x;
    }
    th {
        font-weight: 600;
        color: white;
        background-color: $sp-blue;
        border: 0;
        padding: 10px 20px;
    }
    tr:nth-of-type(even){
        background: #{$background-gray};
    }
    .bi-link {
        font-size: 26px;
        color: $red;
        line-height: 1;
    }
}

.CAVDStudyResults .results-table th {
    width: 22%;
}

.CAVDPublications .results-table th {
    width: 22%;
}

.no-results {
    text-align: center;
    margin-top: 40px;
}

.table-loading {
    width: 100%;
    overflow: hidden;
    tbody {
        visibility: hidden;
        height: 1px;
        td {
            border: 0;
            display: block;
            height: 0px;
            padding: 0;
        }
    }
}