@import '../vars.scss';

.publication-tile-banner {
    @include verticalSpacing;
    &.centered {
        .container {
            .tile-container{                
                text-align: center;
                .heading{
                    @include H2;
                    position: relative;
                    color: $heading-blue;
                    &:empty {
                        margin: 0;
                    }
                }
                .banner-link{
                    align-items: center;
                }
                & > .paragraph {
                    @include H4;
                    @include col8;
                    margin: 0 auto $vertical-spacing;
                }
            }
            
        }
    }
    &.left-image {
        .container {  
            @media(max-width: $min-lg) {                
                .img-container {
                    display:block;                 
                }
                .tile-container{
                    display: block;
                    text-align: center;
                }
            } 
            @media(min-width: $min-lg) { 
                max-width: none;
                width: 100%;      
                display: flex;  
                .img-container {
                    order: 1;
                    width: 50%;
                }
                .tile-container{
                    order: 2;
                    padding-left: 40px;
                    width: 50%;
                    .col {
                        width: 100%;
                    }
                }
            }
            @media(min-width: $min-xl){
                .img-container {
                    width: 60%;
                    margin-left: -16px;
                }
                .tile-container {
                    width: 40%;
                }
                .banner-link{
                    margin-left: 35px;
                }
            }
            @media (min-width: $min-xxl) {
                max-width: 1440px;
            }
        }
    }
    &.left-text-only {
        .tile-container {
            position: relative;
        }
        .col {
            margin-bottom: $spacer-4x;
            display: flex;
            justify-content: center;
        }
        .heading {
            margin-bottom: 50px;
            @include H2;
            color: $heading-blue;
        }
        .subheading {
            margin-bottom: 30px;
            font-size: 24px;
            color: $heading-blue;
        }
        .banner-link {
            position: absolute;
            top: 0;
            right: 0;
        }
        @media(max-width:767px) {
            display: block;
            text-align: center;
            .banner-link {
                position: unset;
            }
        }
    }
    &.no-text {
        .col {
            margin-bottom: $spacer-4x;
            display: flex;
            justify-content: center;
        }
    }
    &.bg-image {
        .heading {
            @include H2;
            //color: $heading-blue;
        }
        .tile-container > .heading {
            @media(min-width: $min-lg){
                margin-top: 175px;
            }
        }
        .paragraph {
            width: 50%;
            @include H4;
            margin-bottom: $spacer-8x;
            color: $dark-text;
        }
        @media (max-width: $min-sm) {
            .row {
                margin-bottom: $spacer-4x;
            }
        }
        @media (max-width: $min-lg) {
            background: none !important;
            .paragraph {
                width: 90%;
            }
            .col {
                margin-bottom: $spacer-4x;
            }
        }
    }
    .row-cols-xl-2 {
        .large-image .img-fluid {
            height: 260px;
        }
    }
}

.filter-heading {
    margin: 15px 10px 5px 0px;
    font-size:1.05em;
    color: #595959;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.multi-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: left;
    width: 100%;

    .buttonWhite {
        background-color: white;
        color: black;
        border: 2px solid var(--color);
    }
    .buttonblack {
        background-color: #181717;
        color: white;
        border: 2px solid #181717;
    }
    .buttonmediumseagreen {
        background-color: #008376;
        color: white;
        border: 2px solid #008376;
    }
    .buttonsteelblue {
        background-color: #0070c0;
        color: white;
        border: 2px solid #0070c0;
    }
    .buttonorange {
        background-color: #ff5800;
        color: white;
        border: 2px solid #ff5800;
    }
    .buttonrebeccapurple {
        background-color: #7030a0;
        color: white;
        border: 2px solid #7030a0;
    }
    .buttongray {
        background-color: #bfbfbf;
        color: black;
        border: 2px solid #bfbfbf;
    }
    .buttongold {
        background-color: #f7ff08;
        color: black;
        border: 2px solid #f7ff08;
    }
    .buttonbrown {
        background-color: #7f2c00;
        color: white;
        border: 2px solid #7f2c00;
    }
    .buttonred {
        background-color: #ff0000;
        color: white;
        border: 2px solid #ff0000;
    }
    button {
        margin: 10px;
        width: 100%;
        border-radius: 0.4em;
        text-align: center;
        font-size:1.05em;
        display:block;
        left:-60px;
        min-height: 40px;
        min-width: 150px;  
        max-width: 300px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
    
}
.bi-info-circle{
    margin-left: 5px;
}

.no-results {
    text-align: center;
    margin-top: 40px;
}

.clearfilterBtn{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}

.searchGroup input[type=text]{
    padding-right: 30px;
}

.searchGroup{
    position: relative;
    display: inline-block;
}

.searchGroup i{
            position: absolute;
            padding-top: 3px;
            left: 94%;

            @media (max-width: $min-sm) {
                left:89%;
            }
            @media (max-width: $min-lg) {
                left: 93%;
            }
}


       
